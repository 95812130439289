import React, { Component } from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import CookiesDisc from '../../components/Layout/CookiesDisc/CookiesDisc';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Aux from '../Aux/Aux';

class Layout extends Component {
  canonicalURL = () => {
    return `https://carwaysmobility.es${this.props.location.pathname}`;
  }

  render(){
    return(
      <Aux>
        <Helmet>
          <title>Concesionario de coches de segunda mano y ocasión | Carways.es</title>
          <meta
            name="description"
            content="Concesionario de coches de ocasión en Viladecans. Aceptamos tu coche como parte de pago. Estudio de la financiación gratuito. Llámanos."
          />
          <meta
            name="keywords"
            content="concesionario coches de segunda mano, coches de ocasión, coches de segunda mano, venta de coches online, coches de importación"
          />
          <link rel='canonical' href={this.canonicalURL()} />
        </Helmet>
        {
          this.props.location.search.indexOf('utm_source') === -1 ?
          <Header isAuth={this.props.isAuthenticated} location={this.props.location} />
          :
          null
        }
        {this.props.children}
        <Footer location={this.props.location} />
        <CookiesDisc />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token != null
  }
}

export default connect(mapStateToProps)(Layout);
